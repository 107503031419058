import API, {authConfig} from "../utils/api";
import {LOGIN} from "../constants/actions";

export const login = (payload) => {
    return async function (dispatch) {
        const data = `grant_type=password&username=${payload.username}&password=${payload.password}`;
        try {
            const response = await API.post(`oauth/token`, data, {
                headers: {"Content-Type": "application/x-www-form-urlencoded",},
                auth: {
                    username: "dWVyMGl1YWRi1uer0idWVyMGl1YWRuadioufqiou",
                    password: "8eU3EPXlrHB6ntSglPg0j"
                }
            });
            dispatch({ type: LOGIN, payload: response.data });
            return response;
        } catch (e) {
            throw e;
        }
    }
}