import {allCharsRegex} from "../constants/regex";

enum Classnames {
    SPM = "spelling-mistake",
    GRMS = "grammar-mistake-sure",
    GRMSU = "grammar-mistake-unsure",
    STY = "style",
    ANA = "analyze-morphology"
}

export const separateWords = (text) => {
    console.log(text);
    const rows = text.split("\n");
    const text_map: Array<any> = [];
    rows.forEach(row => {
        console.log(row);
        const trimmed_row = trimSpaces(row);
        const words = trimmed_row.split(" ");
        words.forEach((word, index) => {
            const wordIsValid = word && allCharsRegex.test(word);
            wordIsValid && text_map.push({
                word: word,
                index: text_map.length,
                isStartOfLine: index === 0,
                isEndOfLine: (index === words.length - 1)
            });
        });
    });
    return text_map;
}

export const prepareHtml = (text: string, suggestions: Array<any>) => {
    console.log(suggestions);
    suggestions.forEach((suggestion: any, index) => {
        const replace = "<spann className='span'>" + suggestion.originalWord + "</spann>";
        text = text.replace(suggestion.originalWord, replace);
    });
    return text;
    // const html: Array<any> = []
    // text.forEach((word, index) => {
    //     const suggestion = suggestions.find(suggestion => suggestion.mistakeType !== "OK" && suggestion.wordIndex === index);
    //     const block = createHtmlBlock(word, suggestion);
    //     // console.log(block)
    //     html.push(block);
    // });
    // return html.flat().join(" ");
}

export const getWordSuggetions = (suggestions: any, index: string) => {
    const filtered = suggestions.find(suggestion => (suggestion.wordIndex) === parseInt(index));
    return filtered.wordSuggestions.map(suggestion => suggestion.wordSuggestion);
}

export const createWordElement = (text, className, key) => {
    return "<span data-word-index='" + key + "' class='text-word " + className + "' key='" + key + "'>" + text + "</span>"
}

export const createHtmlBlock = (word, suggestion) => {
    const html: Array<any> = []
    if(suggestion) {
        if(word.isStartOfLine) html.push("<div>");
        html.push(createWordElement(word.word, Classnames[suggestion.mistakeType], word.index));
        if(word.isEndOfLine) html.push("</div>")
    } else {
        if(word.isStartOfLine)  html.push("<div>")
        html.push(word.word);
        if(word.isEndOfLine) html.push("</div>")
    }
    return html;
}

export const trimSpaces = (text) => {
    const trimmed = text.replace("\n", " ").replace(/\s+/g,' ').trim();
    const final_sentence: Array<any> = [];
    const words = trimmed.split(" ")
    words.forEach(word => {
        const valid = word && allCharsRegex.test(word);
        // console.log(word, valid);
        if(valid) {
            final_sentence.push(word);
        }
    });
    // console.log(final_sentence);
    return final_sentence.join(" ");
}


