import {LOGOUT, SET_LOADING, SET_SUGGESTIONS, SET_SUGGESTIONS_VISIBILITY} from "../constants/actions";

export interface Action {
    type: string;
    payload?: any;
}

export const logout = () => ({
    type: LOGOUT,
    payload: null
});

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload: payload
});

export const setSuggestions = (payload) => ({
    type: SET_SUGGESTIONS,
    payload: payload
});

export const setSuggestionsVisibility = (payload) => ({
    type: SET_SUGGESTIONS_VISIBILITY,
    payload: payload
});
