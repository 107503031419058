import axios from 'axios';
// const baseURL = process.env.BASE_URL;

const baseUrl = "https://drejtshkruaj-develop.herokuapp.com/";
// const baseUrl = "http://172.20.10.9:8000";

const instance = axios.create({
    baseURL: baseUrl
});

export const authConfig = {
    headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
    }
}

instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
   console.log({...error});

    // toastr.error(error.response.statusText);

    return Promise.reject(error);
});

export default instance;
