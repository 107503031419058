import React from "react";
import styles from "./Statistic.module.scss";
import classNames from "classnames";

export enum StatisticTypes {
    Red = "red",
    Purple= "purple",
    Orange = "orange",
    Blue = "blue",
    Pink = "pink",
    Green = "green",
    Disabled = "disabled",
}

interface Props {
    number: number;
    text: string;
    type: StatisticTypes;
    className?: string;
}

const Statistic: React.FC<Props> = ({number, text, type, className}) => {
    const classnames = classNames(styles.statistic, className, {
       [styles.red]: type === StatisticTypes.Red,
       [styles.pink]: type === StatisticTypes.Pink,
       [styles.purple]: type === StatisticTypes.Purple,
       [styles.orange]: type === StatisticTypes.Orange,
       [styles.blue]: type === StatisticTypes.Blue,
       [styles.green]: type === StatisticTypes.Green,
       [styles.disabled]: type === StatisticTypes.Disabled,
    });
    return (
        <div className={classnames}>
            <div className={styles.statisticNumber}>
                {number}
            </div>
            <div className={styles.statisticText}>
                {text}
            </div>
        </div>
    )
}

export default Statistic;
