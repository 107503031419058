import React, {useEffect, useRef} from "react";
import styles from "./Suggestions.module.scss";
import {connect} from "react-redux";
import {setSuggestionsVisibility} from "../../actions";
import classNames from "classnames";

interface Props {
    suggestions: any;
    setSuggestionsVisibility: any;
    onSuggestionClick: any;
}

const MistakeTypeTitles = {
    SPM: "Vërejtje drejtshkrimore",
    GRMS: "Vërejtje gramatikore",
    GRMSU: "Vërejtje gramatikore*",
    STY: "Pikësim",
}

const Suggestions: React.FC<Props> = ({ suggestions, setSuggestionsVisibility, onSuggestionClick }: Props) => {

    const ref = useRef<any>();

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        }
    }, []);

    const handleOutsideClick = e => {
        if(!ref?.current?.contains(e.target)) {
            setSuggestionsVisibility(false);
        }
    }

    const handleSuggestionClick = (e, suggestion) => {
        onSuggestionClick(suggestion);
    }

    const classes = classNames(styles.suggestionsContainer,
        {[styles.spelling]: suggestions.mistakeType === "SPM"},
        {[styles.grammarSure]: suggestions.mistakeType === "GRMS"},
        {[styles.grammar]: suggestions.mistakeType === "GRMU"},
        {[styles.style]: suggestions.mistakeType === "STY"},
    );

    return (
        <div className={classes}
             ref={ref}
             style={{
                 position: "fixed",
                 top: suggestions.position.top,
                 left: suggestions.position.left
             }}>
            <div className={styles.header}>
                <span className={styles.title}>
                    {MistakeTypeTitles[suggestions.mistakeType]}
                </span>
                <span className={styles.closeIcon}
                      onClick={() => setSuggestionsVisibility(false)}>&#x2715;</span>
            </div>
            <div className={styles.suggestions}>
                {suggestions.suggestions.map((suggestion: any, index: number) =>
                    <div key={`suggestion-${index}`}
                         className={styles.suggestion}
                         onClick={(e) => handleSuggestionClick(e, suggestion)}>
                        {suggestion.wordSuggestion}
                    </div>)}
            </div>
        </div>)
}

const mapStateToProps = state => ({
    suggestions: state.app.suggestions,
    isSuggestionsOpen: state.app.isSuggestionsOpen,
});
const mapDispatchToProps = dispatch => ({
    setSuggestionsVisibility: (data) => dispatch(setSuggestionsVisibility(data)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Suggestions);
