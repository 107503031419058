import * as constants from "../constants/actions";
import {Action} from "../actions";

export interface AppState {
    user: object | null;
    suggestions: any;
    isSuggestionsOpen: boolean;
}

const getInitialState = () => {
    return {
        user: null,
        originalWord: "",
        isSuggestionsOpen: false,
        suggestions: {
            mistakeType: "",
            originalWord: "",
            suggestions: [],
            position: {
                top: 0,
                left: 0
            },
            selection: {
                start: 0,
                end: 0
            }
        },
        access_token: localStorage.getItem("access_token"),
    }
}

const app = (state: AppState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.LOGIN: {
            localStorage.setItem("access_token", action.payload.access_token);
            return {
                ...state,
                access_token: action.payload.access_token
            };
        }

        case constants.LOGOUT: {
            localStorage.removeItem("access_token");
            return {...getInitialState()};
        }

        case constants.SET_SUGGESTIONS: {
            return {...state, suggestions: action.payload};
        }

        case constants.SET_SUGGESTIONS_VISIBILITY: {
            return {...state, isSuggestionsOpen: action.payload};
        }

        default: {
            return {...state};
        }

    }
}

export default app;
