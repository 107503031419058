import API, {authConfig} from "../utils/api";

export const checkSpelling = (data) => {
    return async function(dispatch) {
        try {
            return API.post("corrections/spelling", data, authConfig);
        } catch (err) {
            throw err;
        }
    }
}

export const checkMeaning = (data) => {
    return async function(dispatch) {
        try {
            return API.post("corrections/meaning", data, authConfig);
        } catch (err) {
            throw err;
        }
    }
}

export const checkBarbarisms = (data) => {
    return async function(dispatch) {
        try {
            return API.post("corrections/barbarisms", data, authConfig);
        } catch (err) {
            throw err;
        }
    }
}

export const checkArticles = (data) => {
    return async function(dispatch) {
        try {
            return API.post("corrections/articles", data, authConfig);
        } catch (err) {
            throw err;
        }
    }
}
