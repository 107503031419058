import React, {useEffect, useRef, useState} from "react";
import styles from "./Mistake.module.scss";
import classNames from "classnames";
import {connect} from "react-redux";
import {setSuggestions, setSuggestionsVisibility} from "../../actions";

interface Props {
    children: any;
    start: number;
    end: number;
    contentState: any;
    blockKey: any;
    entityKey?: string;
    className?: string;
    setSuggestions: any;
    setSuggestionsVisibility: any;
}

export enum MistakeTypes {
    SpellingMistake = "SPM",
    GrammarMistakeSure = "GRMS",
    GrammarMistakeUnsure = "GRMSU",
    StyleMistake = "STY",
    AnalyzeMorphology = "analyze-morphology"
}

const Mistake: React.FC<Props> = (props: Props) => {

    const { blockKey, start, end, entityKey, contentState, className, children, setSuggestions, setSuggestionsVisibility} = props;
    const mistakeType = contentState.getEntity(entityKey).getData().mistakeType;
    console.log(mistakeType);
    const ref = useRef<any>();

    const classnames = classNames(styles.mistake, className, {
        [styles.spelling]: mistakeType === MistakeTypes.SpellingMistake,
        [styles.grammarSure]: mistakeType === MistakeTypes.GrammarMistakeSure,
        [styles.grammar]: mistakeType === MistakeTypes.GrammarMistakeUnsure,
        [styles.style]: mistakeType === MistakeTypes.StyleMistake
    });


    const handleClick = e => {
        e.stopPropagation();
        const entity = contentState.getEntity(entityKey);
        if(entity) {
            const entityData = entity.getData();

            const top = ref.current.getBoundingClientRect().top;
            const left = ref.current.getBoundingClientRect().left;
            const height = ref.current.getBoundingClientRect().height;
            const position = {
                top: top + height + 5,
                left: left
            };
            const selection = {
                start: start,
                end: end
            };

            const suggestions = {
                suggestions: entityData.suggestions,
                originalWord: entityData.originalWord,
                mistakeType: entityData.mistakeType,
                position: position,
                selection: selection,
                blockKey: blockKey
            }
            console.log(suggestions);
            setTimeout(() => {
                setSuggestions(suggestions);
                setSuggestionsVisibility(true);
            }, 150);

        }

    }

    return (
        <span className={classnames}
            onClick={handleClick}
            ref={ref}>
            {children}
        </span>
    )

}

const mapDispatchToProps = dispatch => ({
    setSuggestions: (data) => dispatch(setSuggestions(data)),
    setSuggestionsVisibility: (data) => dispatch(setSuggestionsVisibility(data)),
});
const connector = connect(null, mapDispatchToProps);
export default connector(Mistake);
