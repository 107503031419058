import {convertToRaw} from 'draft-js';

export const mistakeStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((char) => findCharacterEntity(char, contentState), callback);
}

export const findCharacterEntity = (character, contentState) => {
    const entityKey = character.getEntity();
    return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'MISTAKE'
    );
}

export const getEditorText = (editorState) => {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const mappedBlocks = blocks.map(
        block => (!block.text.trim() && "\n") || block.text
    );

    let newText = "";
    for (let i = 0; i < mappedBlocks.length; i++) {
        const block = mappedBlocks[i];

        // handle last block
        if (i === mappedBlocks.length - 1) {
            newText += block;
        } else {
            // otherwise we join with \n, except if the block is already a \n
            if (block === "\n") newText += block;
            else newText += block + "\n";
        }
    }
    return newText;
}
