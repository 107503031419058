import React from "react";
import "./Header.scss";
import {logout} from "../../actions";
import {connect} from "react-redux";

interface Props {
    logout: any;
}

const Header = ({logout}: Props) => (
    <div className="header">
        <div className="header-top">
            <div className="logo">
                Drejtshkruaj
            </div>
            <div className="nav">
                <div className="nav-item"
                    onClick={logout}>
                    Shkyçu
                </div>
            </div>
        </div>

    </div>
)

const mapDispatchToProps = dispatch => ({
   logout: () => dispatch(logout())
});

const connector = connect(null, mapDispatchToProps);

export default connector(Header);
