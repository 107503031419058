import React from 'react';
import './App.css';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import Login from "./containers/Login";
import Correct from "./containers/Correct";
import Header from "./components/Header";
import {connect} from "react-redux";
import Authenticated from "./components/Routes/Authenticated";

interface Props {
    access_token: any;
}

function App({access_token}: Props) {
    const isAuthenticated = !!access_token;
    return (
        <div className="main-container">
            {isAuthenticated && <Header/>}
            <Switch>
                <Authenticated exact path="/" component={Correct} authenticated={isAuthenticated}/>
                <Route path="/login" render={(props) => {
                    return (
                        !isAuthenticated ?
                            <Login {...props} /> : <Redirect to="/"/>
                    )
                }}/>
            </Switch>
        </div>
    );
}

const mapStateToProps = state => ({
    access_token: state.app.access_token
});

const connector = connect(mapStateToProps);

export default connector(App);
