import React, {Component} from "react";
import "./Login.scss";
import {login} from "../../actions/login";
import {connect} from "react-redux";
import {setLoading} from "../../actions";

interface Props {
    login: any;
    history: any;
    setLoading: any;
}

interface State {
    email: string;
    password: string;
}

class Login extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            email: "",
            password: ""
        };
    };

    handleSubmit = async() => {
        const {login, history, setLoading} = this.props;
        const {email, password} = this.state;
        if(!email || !password) {
            return;
        }
        const payload = {
            username: email,
            password: password,
            grant_type: "password"
        };
        setLoading(true);
        await login(payload)
            .then(res => {
                setLoading(false);
                window.location.reload();
                history.push("/");
            });
    }

    render() {
        const {email, password} = this.state;
        return (
            <div className="login-page">
                <div className="login-box">
                    <h2 className="login-title">
                        Kyçu
                    </h2>
                    <input
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => this.setState({email: e.target.value})}/>
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => this.setState({password: e.target.value})}/>
                    <button
                        className="primary-button login-button"
                        onClick={this.handleSubmit}>
                        Kyçu
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    //
});

const mapDispatchToProps = dispatch => ({
    login: (payload) => dispatch(login(payload)),
    setLoading: (payload) => dispatch(setLoading(payload))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Login);
