import React, {Component} from "react";
import "./Correct.scss";
import {checkArticles, checkBarbarisms, checkMeaning, checkSpelling} from "../../actions/corrections";
import {trimSpaces} from "../../utils";
import {connect} from "react-redux";
import Statistic, {StatisticTypes} from "../../components/Statistic";
import {convertToRaw, EditorState, Modifier, SelectionState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import {findCharacterEntity, getEditorText, mistakeStrategy} from "../../utils/draft";
import Mistake from "../../components/Mistake";
import Suggestions from "../../components/Suggestions";
import {setSuggestionsVisibility} from "../../actions";


interface Props {
    checkSpelling: any;
    checkBarbarisms: any;
    checkMeaning: any;
    checkArticles: any;
    isSuggestionsOpen: boolean;
    suggestions: any;
    setSuggestionsVisibility: any;
}

interface State {
    words: number;
    characters: number;
    numberOfIssues: number;

    suggestions: any;
    editorState: any;

    madeCorrection: boolean;
}

const compositeDecorators = [{
    component: Mistake,
    strategy: mistakeStrategy,
}];

class Correct extends Component<Props, State> {
    constructor(props: Props) {
        super(props);


        this.state = {
            words: 0,
            characters: 0,
            numberOfIssues: 0,

            suggestions: [],
            editorState: EditorState.createEmpty(),

            madeCorrection: false
        }
    }


    componentDidMount(): void {

    }

    onEditorStateChange: Function = (editorState) => {
        const {suggestions} = this.state;

        let nextState = editorState;
        let contentState = editorState.getCurrentContent();
        const blocks = nextState
            .getCurrentContent()
            .getBlocksAsArray();

        let entityAdded = false;
        let entityRemoved = false;

        const text = trimSpaces(getEditorText(nextState));
        const words = text ? text.split(" ") : [];
        const characters = text.replace(/ /g,'');
        const numberOfWords = words.length;
        const numberOfCharacters = characters.length;

        if(!text) {
            this.setState({
               suggestions: [],
                words: 0,
                characters: 0,
                numberOfIssues: 0,
                madeCorrection: false
            });
        }

        //go through all the blocks
        blocks.forEach(block => {
            const text = block.getText();

            block.findEntityRanges(
                (char) => findCharacterEntity(char, contentState),
                (start, end) => {
                    const entityKey = block.getEntityAt(start);
                    const entity = contentState.getEntity(entityKey);
                    const textWithEntity = text.substring(start, end);

                    console.log(textWithEntity, entity.getData().originalWord, start, end);

                    if(textWithEntity !== entity.getData().originalWord) {

                        let selection = SelectionState.createEmpty(block.getKey());
                        selection = selection.merge({ anchorOffset: start, focusOffset: end });

                        const contentStateWithRemovedEntity = Modifier.applyEntity(contentState, selection, null);
                        nextState = EditorState.set(editorState, {currentContent: contentStateWithRemovedEntity});

                        this.setState({
                                editorState: nextState,
                                characters: numberOfCharacters,
                                words: numberOfWords
                            },
                            () => console.log(JSON.parse(JSON.stringify(convertToRaw(nextState.getCurrentContent())))));
                        entityRemoved = true;
                    }
                }
            );

            suggestions.forEach((suggestion) => {
                const regex = new RegExp("\\b" + suggestion.originalWord + "\\b", 'g');
                const locations = [...text.matchAll(regex)].map(a => a);

                const filteredSuggestions = suggestions.filter(fSuggestion => fSuggestion.originalWord == suggestion.originalWord);
                const indexOfCurrentSuggestion = filteredSuggestions.findIndex(iSuggestion => iSuggestion.wordIndex == suggestion.wordIndex);

                locations.forEach((location, i) => {

                    if(indexOfCurrentSuggestion !== i) {
                        return;
                    }

                    //find the text we need to apply entity inside the block
                    const index = location;

                    if (block.getEntityAt(index.index)) return;
                    if(suggestion.mistakeType === "OK") return;

                    //create the entity
                    const contentStateWithEntity = nextState
                        .getCurrentContent()
                        .createEntity('MISTAKE', 'MUTABLE', {
                            mistakeType: suggestion.mistakeType,
                            originalWord: suggestion.originalWord,
                            suggestions: suggestion.wordSuggestions,
                        });

                    //get the entity key
                    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

                    //apply selection to the part of text that we want to apply the entity
                    let selection = SelectionState.createEmpty(block.getKey());
                    selection = selection.merge({ anchorOffset: index.index, focusOffset: index.index + index[0].length });

                    console.log(selection.serialize());

                    //apply the entity to the contentState
                    const contentStateWithAppliedEntity = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);

                    //create the new editorState with the new contentState
                    nextState = EditorState.set(nextState, {
                        currentContent: contentStateWithAppliedEntity,
                    });

                    if(!entityRemoved) {
                        //set the new editorState
                        this.setState({
                            editorState: nextState,
                            characters: numberOfCharacters,
                            words: numberOfWords
                        }, () => {
                            console.log(
                                JSON.parse(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
                            );
                        });

                        entityAdded = true;
                    }

                });
            });
        });
        if(!entityAdded && !entityRemoved) {
            this.setState({
                editorState: nextState,
                characters: numberOfCharacters,
                words: numberOfWords
            });
        }
    }

    onSuggestionClick = (suggestion) => {
        const {suggestions} = this.props;
        const {editorState} = this.state;
        console.log(suggestion);
        console.log(suggestions);

        let nextState = editorState;

        let selection = SelectionState.createEmpty(suggestions.blockKey);
        selection = selection.merge({ anchorOffset: suggestions.selection.start, focusOffset: suggestions.selection.end });

        const contentStateWithReplacedText = Modifier.replaceText(nextState.getCurrentContent(), selection, suggestion.wordSuggestion);
        nextState = EditorState.set(nextState, {
            currentContent: contentStateWithReplacedText,
        });

        this.setState({
            editorState: nextState
        }, () => {
            this.props.setSuggestionsVisibility(false);
            console.log(JSON.parse(JSON.stringify(convertToRaw(editorState.getCurrentContent()))))
        });
    }

    handleSubmit = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {
        const {editorState} = this.state;
        const oldState = editorState;
        const payload = getEditorText(editorState);

        const handleSetHtml = (suggestions) => {
            const numberOfIssues = suggestions.filter(suggestion => suggestion.mistakeType !== "OK").length;

            this.setState({
                editorState: EditorState.createEmpty()
            }, () => {
                this.setState({
                    suggestions,
                    numberOfIssues,
                    madeCorrection: true
                }, () => {
                    this.onEditorStateChange(oldState);
                });
            });
        }

        if (type === "spelling") {
            this.props.checkSpelling({text: payload}).then(res => {
                handleSetHtml(res.data.data);
                // this.setWordsEventListeners();
            });
        } else if (type === "barbarisms") {
            this.props.checkBarbarisms({text: payload}).then(res => {
                handleSetHtml(res.data.data);
            });
        } else if (type === "meaning") {
            this.props.checkMeaning({text: payload}).then(res => {
                handleSetHtml(res.data.data);
            });
        } else if (type === "articles") {
            this.props.checkArticles({text: payload}).then(res => {
                handleSetHtml(res.data.data);
            });
        }
    }

    onEditorKeyPress = (e) => {

    }

    render() {
        const {isSuggestionsOpen} = this.props;
        const {characters, words, numberOfIssues, editorState, madeCorrection} = this.state;
        const statisticType = madeCorrection ? characters && numberOfIssues ? StatisticTypes.Red : characters && !numberOfIssues ? StatisticTypes.Green : StatisticTypes.Disabled : StatisticTypes.Disabled;

        return (
            <div className="correct-container">

                <div className="text-box-container">

                    <Editor
                        customDecorators={compositeDecorators}
                        wrapperClassName="draft-editor-wrapper"
                        editorClassName="draft-editor-editor"
                        toolbarClassName="draft-editor-toolbar"
                        editorState={editorState}
                        onEditorStateChange={this.onEditorStateChange}/>

                    {isSuggestionsOpen &&
                    <Suggestions
                        onSuggestionClick={this.onSuggestionClick}/>}

                    <div className="statistics">
                        <div className="statistics-issues">
                            <Statistic
                                number={numberOfIssues}
                                text="vërejtje gjuhësore"
                                type={statisticType}/>
                        </div>
                        <div className="statistics-counts">
                            <div>{characters} karaktere, </div>
                            <div>{words} fjalë</div>
                        </div>
                    </div>
                </div>

                <div className='buttons'>
                    <button className="submit-button red"
                            onClick={(e) => this.handleSubmit(e, "spelling")}>
                        Përmirëso
                    </button>

                    <button className="submit-button blue"
                            onClick={(e) => this.handleSubmit(e, "barbarisms")}>
                        Shqipëro
                    </button>

                    <button className="submit-button green"
                            onClick={(e) => this.handleSubmit(e, "meaning")}>
                        Shpjego
                    </button>

                    <button className="submit-button final"
                            onClick={(e) => this.handleSubmit(e, "articles")}>
                        Përfundo
                    </button>
                </div>

                <div className="bottom-text">
                    Përmbi zâ qi lshon bylbyli<br/>
                    Gjûha shqype m'shungullon;<br/>
                    Permbi érë qi nep zymbyli<br/>
                    Pá da zêmren m'a ngushllon.<br/>
                    <br/>
                    Geg' e Toskë, malci, jallija<br/>
                    Jân nji kômb, m'u da s'duron:<br/>
                    Fund e maje nji â Shqypnija,<br/>
                    E nji gjuhë t'gjith na bashkon.<br/>
                    <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Ndre Mjeda</b>
                </div>
                <p className="copyright-text">drejtshkruaj©{new Date().getFullYear()}</p>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    isSuggestionsOpen: state.app.isSuggestionsOpen,
    suggestions: state.app.suggestions
});
const mapDispatchToProps = dispatch => ({
    checkSpelling: data => dispatch(checkSpelling(data)),
    checkMeaning: data => dispatch(checkMeaning(data)),
    checkBarbarisms: data => dispatch(checkBarbarisms(data)),
    checkArticles: data => dispatch(checkArticles(data)),
    setSuggestionsVisibility: data => dispatch(setSuggestionsVisibility(data)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Correct);
