import React from "react";
import { Route, Redirect } from "react-router-dom";

interface Props {
    path: string;
    component: any;
    authenticated: any;
    exact?: boolean;
}

const Authenticated = ({ path, component, authenticated, exact }: Props) => (
    authenticated ?
        <Route path={path} component={component} exact={exact} />
            : <Redirect to="/login" />
)

export default Authenticated;
